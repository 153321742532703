import config from '../config';
import { React, useContext, useEffect, useState } from 'react';
import { Box, Button, Container, FormControlLabel, Input, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { LoadingButton } from '../components/exports';
import { Auth } from 'aws-amplify';
import AppInfoParser from 'app-info-parser'

import { ApiClient } from '../models/exports'

import { EnvContext, PermissionContext } from '../providers/exports';

import {
    Cast as CastIcon,
} from '@mui/icons-material';
import { ProductNames } from '../constants/ProductNames';

function formatDate(dateString) {
    const d = new Date(dateString);
    if (isNaN(d.getTime())) return '';

    return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日 ${d.getHours()}時${d.getMinutes()}分${d.getSeconds()}秒`
}

export function EditPage(props) {
    const id = props.match.params.id;

    const [packageInfo, setPackageInfo] = useState({
        model_name: '',
        version_code: '',
        version_name: '',
        created_at: '',
        updated_at: '',
        product_name: '',
        visibility: false,
        bin_path: '',
        bin_key: '',
        tags: [],
    });

    const [isApk, setIsApk] = useState(false);

    const [loading, setLoading] = useState(false);

    const [file, setFile] = useState(null);

    const [tags, setTags] = useState('');

    const { env } = useContext(EnvContext);
    const { permission } = useContext(PermissionContext);

    useEffect(() => {
        getPackageById(parseInt(id));
    }, []);

    const api = new ApiClient({});

    const checkPermission = packageInfo => {
        return (() => {
            for (let p of permission) {
                if (p.type === 'product_name' && p.value === packageInfo.product_name) {
                    return true;
                }
                else if (p.type === 'model_name' && p.value === packageInfo.model_name) {
                    return true;
                }
            }

            return false;
        })();
    }

    async function getPackageById(id) {
        const path = `${config[env].tapi.endpoint}/firmwares`;
        const params = {
            'or': `(id.eq.${id})`,
        }

        const [result, err] = await api.get(path, params);
        console.log(env)
        console.log(id)
        console.log(result)
        console.log(err)
        if (err !== null) return null;

        if (result == null || result.length <= 0) {
            alert('登録情報がありません');
            props.history.replace('/')
            return;
        }

        console.log(result);

        if (!checkPermission(result[0])) {
            alert('編集権限がありません');
            props.history.replace('/')
            return;
        }

        setPackageInfo(result[0]);

        setIsApk(result[0].product_name === ProductNames.Android);
        setTags(result[0].tags.join(', '));
    }

    async function putBinURL(idToken, id) {
        const path = `${config[env].tapi.endpoint}/firmwares/${id}/bin`;

        return await api.put(path, {}, { Authorization: idToken });
    }

    async function uploadBin(url, file) {
        // const path = `/api/upload.php`;
        // const params = new FormData();

        // console.log(file)
        // params.append('file', file);
        // params.append('url', url);

        // const header = {
        //     'Content-Type': 'multipart/form-data',
        // };

        // return await api.post(path, params, header);

        const params = new FormData();
        params.append('file', file);

        const header = {
            'Content-Type': 'multipart/form-data',
        };

        return await api.put(url, params, header);
    }

    const validateFile = async e => {
        setLoading(true);
        await (async () => {
            const file = e.target.files[0];
            if (file === undefined) {
                setFile(null);
                return;
            }

            if (isApk) {
                const reason = await parseApk(file);
                if (reason !== null) {
                    alert(reason);
                    e.target.value = '';
                    setFile(null);
                    return;
                }
            }

            setFile(file);
        })();
        setLoading(false);
    }

    const parseApk = async file => {
        try {
            const parser = new AppInfoParser(file);
            const result = await parser.parse();
            const { package: modelName, versionCode, versionName } = result;

            if (modelName !== packageInfo.model_name) {
                return 'パッケージ名不一致';
            }
            if (versionCode !== packageInfo.version_code) {
                return 'バージョン不一致';
            }

            setPackageInfo({ ...packageInfo, version_name: versionName })

            return null;
        } catch (e) {
            return 'パッケージ解析エラー';
        }
    };

    const onUpdateClick = async e => {
        let tagArray = [];
        if (tags) {
            tagArray = tags.split(',').map(t => t.trim());
        }

        setLoading(true);
        await (async () => {
            const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
            if (file) {
                console.log(file);
                const [putBinURLResult, putBinURLError] = await putBinURL(idToken, id);
                console.log(putBinURLResult);
                if (putBinURLError !== null) {
                    alert('バイナリURL登録エラー');
                    return;
                }

                // const [uploadBinResult, uploadBinError] = await uploadBin(putBinURLResult.upload_url, file);
                // if (uploadBinError !== null) {
                //     alert('S3アップロードエラー');
                //     return;
                // }
                const uploadResult = await api.putFile(putBinURLResult.upload_url, file);
                if (!uploadResult) {
                    alert('S3アップロードエラー');
                    return;
                }
            }

            const request = {
                product_name: packageInfo.product_name,
                version_name: packageInfo.version_name,
                visibility: packageInfo.visibility,
                tags: JSON.stringify(tagArray),
            }

            const path = `${config[env].tapi.endpoint}/firmwares/${id}`;

            const [result, error] = await api.patch(path, request, { Authorization: idToken });
            if (error != null) {
                alert('更新失敗');
                return;
            }

            props.history.push('/')
        })();
        setLoading(false);
    };

    const Visibility = ({...props}) => {
        return (
            <button
                style={{
                    width: '160px',
                    height: '56px',
                    fontSize: '1.5rem',
                    color: 'white',
                    marginTop: '10px',
                    borderRadius: '12px',
                    backgroundColor: (packageInfo.visibility ? '#d32f2f' : '#616161'),
                }}
                onClick={toggleVisibility}>
                {packageInfo.visibility ? (<div>公開&nbsp;&nbsp;<CastIcon /></div>) : (<div>非公開</div>)}
            </button>
        )
    };

    const toggleVisibility = () => {
        setPackageInfo({ ...packageInfo, visibility: !packageInfo.visibility })
    }

    return (
        <div>
            <Grid container spacing={10}>
                <Grid item xs={4}>
                    <TextField
                        type="text"
                        label="作成日"
                        fullWidth={true}
                        margin="normal"
                        value={formatDate(packageInfo.created_at)}
                        variant="standard"
                        inputProps={{ readOnly: true }} />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        type="text"
                        label="最終更新日"
                        fullWidth={true}
                        margin="normal"
                        value={formatDate(packageInfo.updated_at)}
                        variant="standard"
                        inputProps={{ readOnly: true }} />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={3}>
                    <Visibility />
                </Grid>
            </Grid>

            <TextField
                type="text"
                label="モデル"
                fullWidth={true}
                margin="normal"
                value={packageInfo.model_name}
                disabled={true} />

            <TextField
                type="text"
                label="プロダクト"
                fullWidth={true}
                margin="normal"
                value={packageInfo.product_name}
                onChange={e => setPackageInfo({ ...packageInfo, product_name: e.target.value })} />

            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <TextField
                        type="number"
                        label="バージョンコード"
                        fullWidth={true}
                        margin="normal"
                        value={packageInfo.version_code}
                        disabled={true} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        type="text"
                        label="バージョンネーム"
                        fullWidth={true}
                        margin="normal"
                        value={packageInfo.version_name}
                        onChange={e => setPackageInfo({ ...packageInfo, version_name: e.target.value })}
                        disabled={isApk ? true : false} />
                </Grid>
            </Grid>

            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <TextField
                        type="text"
                        label="S3 Path"
                        fullWidth={true}
                        margin="normal"
                        value={packageInfo.bin_path}
                        disabled={true} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        type="text"
                        label="S3 Key"
                        fullWidth={true}
                        margin="normal"
                        value={packageInfo.bin_key}
                        disabled={true } />
                </Grid>
            </Grid>

            <TextField
                type="text"
                label="タグ"
                fullWidth={true}
                margin="normal"
                value={tags}
                onChange={e => setTags(e.target.value)} />

            <Box m={2} />

            <Grid container spacing={5}>
                <Grid item xs={7}>
                    <Input
                        type="file"
                        fullWidth={true}
                        onChange={validateFile} />
                </Grid>
            </Grid>


            <Box m={4} />
            <Grid container>
                <Grid item xs={4} />
                <Grid item xs={4}>
                    <LoadingButton loading={loading} variant="contained" color="primary" fullWidth={true} onClick={onUpdateClick}>更新</LoadingButton>
                </Grid>
                <Grid item xs={4} />
            </Grid>
        </div>
    );
}

